import serviceAxios from "../request.js";

export const uploadFile = (params) => {
    return serviceAxios({
        url: "/file/uploadFile",
        method: "post",
        params,
    });
};
// 名单管理
export const clockPresetsList = (data) => {
    return serviceAxios({
        url: "/clockPresets/list",
        method: "post",
        data,
    });
};
// 删除名单
export const clockPresetsDel = (data) => {
    return serviceAxios({
        url: "/clockPresets/del",
        method: "post",
        data,
    });
};
// 编辑名单
export const clockPresetsDetail = (data) => {
    return serviceAxios({
        url: "/clockPresets/detail",
        method: "post",
        data,
    });
};
// 添加预存名单列表
export const clockPresetsAdd = (data) => {
    return serviceAxios({
        url: "/clockPresets/add",
        method: "post",
        data,
    });
};
// 编辑名单
export const clockPresetsUpdate = (data) => {
    return serviceAxios({
        url: "/clockPresets/update",
        method: "post",
        data,
    });
};
// 活动详情
export const activityDetail = (data) => {
    return serviceAxios({
        url: "/activity/activityDetail",
        method: "post",
        data,
    });
};
// 创建活动
export const updateActivity = (data) => {
    return serviceAxios({
        url: "/activity/updateActivity",
        method: "post",
        data,
    });
};
// 编辑填表项
export const updateSignList = (data) => {
    return serviceAxios({
        url: "/activity/updateSignList",
        method: "post",
        data,
    });
};

// 获取封面图片
export const getCoverimgTemplate = (data) => {
    return serviceAxios({
        url: "/activity/getCoverimgTemplate",
        method: "post",
        data,
    });
};
// 获取报名数据
export const getSignList = (data) => {
    return serviceAxios({
        url: "/activity/getSignList",
        method: "post",
        data,
    });
};
// 判断是否是管理员
export const getProjectManageAdmin = (data) => {
    return serviceAxios({
        url: "/activity/getSignList",
        method: "post",
        data,
    });
};